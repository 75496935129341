<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="8">
                <div class="subheading grey--text mb-9">Product List</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn depressed color="grey darken-4" class="white--text" to="/products/new">
                    <v-icon left>mdi-plus</v-icon>
                    New
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toProductDetails">
            <template v-slot:item.imgSrc="{ item }">
                <v-avatar v-if="item.imgSrc != null" size="25">
                    <v-img :src="item.imgSrc" />
                </v-avatar>
            </template>
            <template v-slot:item.active="{ item }">
                <v-chip x-small label :color="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
            </template>
        </v-data-table>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Img', value: 'imgSrc', sortable: false, width: '5%' },
            { text: 'ID', value: 'style', sortable: true },
            { text: 'Description', value: 'description', sortable: true },
            { text: 'Category', value: 'category', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
        ],
        pagination: {
            sortBy: ['style']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',
    }),
    computed: {
        ...mapGetters({
            items           : 'products/products'
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadProducts    : 'products/loadProducts',
            save            : 'products/saveProduct'
        }),
        async init() {
            try {
                this.loading = true;
                await this.loadProducts();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        toProductDetails( prod ) {
            this.$router.push(`/products/${prod.id}`);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>